import React, { useState, useContext } from 'react';
import { WalletContext } from '../WalletContext';
import '../styles/SocialMediaTasks.css';

const tasks = [
  { id: 1, name: 'Facebook', description: 'Like and share our Facebook page', reward: 250, link: 'https://www.facebook.com/profile.php?id=61566619371641' },
  { id: 2, name: 'Twitter', description: 'Follow and retweet us on Twitter', reward: 250, link: 'https://x.com/kasuappx' },
  { id: 3, name: 'YouTube', description: 'Subscribe to our YouTube channel', reward: 250, link: 'https://www.youtube.com/@Kasuapp' },
  { id: 4, name: 'YouTube', description: 'Create a YouTube video and post on your YouTube channel', reward: 3200, link: '' },
];

const SocialMediaTasks = () => {
  const { account, isConnected, merchantLibraryContract } = useContext(WalletContext);
  const [taskUrls, setTaskUrls] = useState({});
  const [error, setError] = useState(null);

  const handleUrlSubmit = async (taskId, event) => {
    if (!isConnected) {
      alert('Wallet not connected');
      return;
    }
    event.preventDefault();
    setError(null);

    const url = event.target.elements.proofUrl.value;

    const taskData = {
      name: tasks.name,
      user: account,
      nonce: 0,
      link: url,
      amount: 0,
      approvedAt: 0,
      isApproved: false,
      isUpdate: false
    }
    
    try {
      await merchantLibraryContract.updateTask(taskData).send({ from: account });
      setTaskUrls({ ...taskUrls, [taskId]: url });
      event.target.reset();
    } catch (error) {
      console.error('Error uploading URL to smart contract:', error);
      setError('Failed to upload URL. Please try again.');
    } finally {
    }
  };

  return (
    <div className="kasu-tasks">
      <h1 className="title">Earn KASU Tokens</h1>
      {error && <div className="error-message">{error}</div>}
      {tasks.map(task => (
        <div key={task.id} className="task-item">
          <div className="task-header">
            <span className="task-description">{task.description}</span>
            <span className="task-reward">{task.reward} KASU</span>
          </div>
          <div className="task-action">
            <a href={task.link} target="_blank" rel="noopener noreferrer" className="social-link">
              Go to {task.name} Task
            </a>
          </div>
          <div className="task-proof">
            <form onSubmit={(e) => handleUrlSubmit(task.id, e)}>
              <input type="text" name="proofUrl" placeholder="Enter proof URL" required />
              <button type="submit" className='social-button'>Submit URL</button>
            </form>
          </div>
          {taskUrls[task.id] && (
            <div className="saved-proof">
              <h4>Submitted Successfully!</h4>
              <a href={taskUrls[task.id]} target="_blank" rel="noopener noreferrer">
                View Submitted Link
              </a>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default SocialMediaTasks;
