// components/Header.js
import { React, useEffect, useContext, useCallback, useState } from 'react';
import Web3 from 'web3';
import MasterABI from '../ABI/MasterABI.json';
import StakingABI from '../ABI/StakingABI.json';
import DataLibraryABI from '../ABI/DataLibraryABI.json';
import MerchantABI from '../ABI/MerchantABI.json';
import MerchantLibraryABI from '../ABI/MerchantLibraryABI.json';
import TokenABI from '../ABI/TokenABI.json';
import logo from '../assets/img/logo/logo.png';
import { WalletContext } from '../WalletContext';
import '../styles/Header.css';

const Header = () => {
  const {
    referee, setReferee, setRemainingDays, web3,
    account, setAccount,setPendingReward, setErninngData,
    isConnected, setIsConnected, setMerchantLibraryContract,
    setWeb3, contract, setContract, connectedChainId, setConnectedChainId,
    setApproved, setDataLibraryContract, setTotalTokenStakedWithoutK,
    masterAddress, setMasterAddress, setBusinessReward, tokenAddress,
    setTokenAddress, setUsdtDecimal, stakingAddress,
    setStakingAddress, setMerchantContract, setTotalEarning, dataLibraryAddress,
    stakingContract, setStakingContract, merchantLibraryAddress, setMerchantLibraryAddress,
    usdtContract, setUsdtContract, setMilestonRewards, setJoiningDate,
    totalRefferals, setTotalRefferals, setUserLavels, merchantAddress, setMerchantAddress,
    setThisMonthEarning, setTodaysEarning, totalEarning,
    setThisYearEarning, dataLibraryContract, setDataLibraryAddress,
    setTotalTokenStaked, setTotalInvest, setReferalIncome
  } = useContext(WalletContext);
  
  const tokenContractAddress = '0xc2132D05D31c914a87C6611C10748AEb04B58e8F';
  const masterContractAddress = '0x6ccB96E42cB8470cD62Be22E461c1fC6bE5b63a4';
  const dataLibraryContractAddress = '0x2eEf614A146190560de9719c3A2a78690EF87Df7';
  const stakingContractAddress = '0x056EcB0f7525b288f5087069eAa21A56a168D679';
  const merchantContractAddress = '0xb932d3e22e665Cf4eEb048ee5878AfCF7DB3d08e';
  const merchantLibraryContractAddress = '0xb932d3e22e665Cf4eEb048ee5878AfCF7DB3d08e';

  // const tokenContractAddress = '0xe586e1237C32811954f4D9f65263692b520d7574';
  // const masterContractAddress = '0xC171B6095E742424901A0dfC438AD9F1BcCf0679';
  // const dataLibraryContractAddress = '0x7B47Bb5273Aa337aE5e709c17e4B32f25641998e';
  // const stakingContractAddress = '0xbCFa5A81632449BBd65AF9D659D9Cf9a68c0a997';
  // const merchantContractAddress = '0x6F8C401e0a0344fbca2a2d5ad8c8cc099dD91f78';
  // const merchantLibraryContractAddress = '0xEa1f309761aca404097Ab170dED244D10F06470c';

  useEffect(() => {
    if (window.ethereum) {
      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);
    } else if (window.web3) {
      const web3Instance = new Web3(window.web3.currentProvider);
      setWeb3(web3Instance);
    } else {
      console.error('No Web3 provider detected');
    }
    setMasterAddress(masterContractAddress);
    setTokenAddress(tokenContractAddress);
    setStakingAddress(stakingContractAddress);
    setDataLibraryAddress(dataLibraryContractAddress);
    setMerchantAddress(merchantContractAddress);
    setMerchantLibraryAddress(merchantLibraryContractAddress);
  }, [setWeb3, setMasterAddress, setTokenAddress, setStakingAddress, setDataLibraryAddress]);

  async function getConnectedChainId() {
    if (!isConnected || !account) {
      console.log('Not connected with web3.');
      return;
    }
    try {
      const _chainId = await web3.eth.getChainId();
      const chainId = Number(_chainId);
      setConnectedChainId(chainId);
      console.log('Chain Id', chainId);
      return chainId;
    } catch (error) {
      console.error('Error getting connected chain ID:', error);
      return null;
    }
  }

  async function switchToPolygonMainnet() {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x89' }],
      });
    } catch (error) {
      if (error.code === 4902) {
        // If the chain is not added, add it
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: '0x89',
              chainName: 'Polygon Mainnet',
              nativeCurrency: {
                name: 'MATIC',
                symbol: 'MATIC',
                decimals: 18,
              },
              rpcUrls: ['https://polygon-rpc.com/'],
              blockExplorerUrls: ['https://polygonscan.com/'],
            },
          ],
        });
      } else {
        console.error('Error switching to Polygon Mainnet:', error);
      }
    }
  }

  useEffect(() => {
    const pathParts = window.location.pathname.split('/');
    const referralIndex = pathParts.indexOf('referral');

    if (referralIndex !== -1 && pathParts.length > referralIndex + 1) {
      const referralAddress = pathParts[referralIndex + 1];
      setReferee(referralAddress);
      console.log(`Referred by:`, referralAddress);
    } else {
      setReferee('nolinkfound');
    }
    console.log("Referraed by:", referee);
    getConnectedChainId();

    async function checkAndSwitchNetwork() {
      if (connectedChainId !== 137) {
        // Chain is not Polygon Mainnet, switch the network
        await switchToPolygonMainnet();
      }
    }
    checkAndSwitchNetwork();
  }, [setReferee, getConnectedChainId, referee]);

  const updateDecimals = useCallback(async () => {
    if (!isConnected || !usdtContract) {
      console.log('User not connected.');
      return;
    }
    try {
      console.log('Updating decimal.');
      const tokenDecimal = await usdtContract.methods.decimals().call();
      const decimalNumber = Number(tokenDecimal);
      console.log('Decimal:', decimalNumber);
      setUsdtDecimal(decimalNumber);
    } catch (error) {
      console.error('Error updating usdt decimals', error);
    }
  }, [isConnected, usdtContract, setUsdtDecimal]);

  const updateData = useCallback(async () => {
    if (!isConnected || !dataLibraryContract || !account) {
      console.log('Library or account not available');
      return;
    }
    try {
      console.log(`Checking staking data for account: ${account}`);
      const tokenDecimal = await usdtContract.methods.decimals().call();
      const decimalNumber = Number(tokenDecimal);
      console.log('Decimal:', decimalNumber);
      // Get the staking time Unix timestamp in seconds
      const _stakingTime = await stakingContract.methods.stakingTime(account).call();
      const stakingTime = Number(_stakingTime.toString());
      const currentTime = Math.floor(Date.now() / 1000);
      const elapsedTime = currentTime - stakingTime;
      let daysStaked = Math.floor(elapsedTime / (60 * 60 * 24));
      const _depositedToken = await stakingContract.methods.depositedTokens(account).call();
      const getTaxNumbers = await dataLibraryContract.methods.getTaxNumbers(account).call();
      const getPendingRewards = await stakingContract.methods.getPendingReward(account).call();
      const getRewardData = await stakingContract.methods.getRewardData(account).call();
      const _dailyReward = await stakingContract.methods.getDailyReward(account).call();

      const dailyReward = Number(_dailyReward.toString());
      const getLavels = await contract.methods.getLevels(account).call();
      const usersLavels = Number(getLavels.toString());

      setUserLavels(usersLavels);

      const stakingStartDate = new Date(stakingTime * 1000);
      const today = new Date();
      const totalDays = Math.floor((today - stakingStartDate) / (1000 * 60 * 60 * 24));
      let erningData = [];

      let currentDate = new Date(stakingStartDate);
      console.log('Erning Data', currentDate);
      if (getRewardData.perDayReward.length > 0) {
        // User has claimed or reinvested before
        let dayCount = 0;
        for (let i = 0; i < getRewardData.perDayReward.length; i++) {
          const _rewardPerDay = Number(getRewardData.perDayReward[i].toString());
          const rewardPerDay = _rewardPerDay / (10 ** decimalNumber);
          const days = Number(getRewardData.totalDays[i].toString());

          for (let j = 0; j < days; j++) {
            erningData.push({
              time: currentDate.toISOString().split('T')[0], // Format: YYYY-MM-DD
              value: rewardPerDay
            });
            currentDate.setDate(currentDate.getDate() + 1);
            dayCount++;
          }
        }

        // Fill remaining days with current daily reward
        const remainingDays = totalDays - dayCount;
        const currentDailyReward = dailyReward / (10 ** decimalNumber); // Convert from wei to token units
        console.log('currentDailyReward', currentDailyReward);
        for (let i = 0; i < remainingDays; i++) {
          erningData.push({
            time: currentDate.toISOString().split('T')[0],
            value: currentDailyReward
          });
          currentDate.setDate(currentDate.getDate() + 1);
        }
      } else {
        // User hasn't claimed or reinvested, use the same daily reward for all days
        const rewardPerDay = dailyReward / (10 ** decimalNumber); // Convert from wei to token units
        for (let i = 0; i < totalDays; i++) {
          erningData.push({
            time: currentDate.toISOString().split('T')[0],
            value: rewardPerDay
          });
          currentDate.setDate(currentDate.getDate() + 1);
        }
      }

      const erninngDatas = [
        { time: '2024-07-10', value: 2 },
        { time: '2024-07-11', value: 5 },
        { time: '2024-07-12', value: 8 },
        { time: '2024-07-13', value: 21 },
        { time: '2024-07-14', value: 8 },
        { time: '2024-07-15', value: 65 },
        { time: '2024-07-16', value: 28 },
        { time: '2024-07-17', value: 23 },
        { time: '2024-07-18', value: 42 },
        { time: '2024-07-19', value: 33 },
        { time: '2024-07-20', value: 24 },
        { time: '2024-07-21', value: 20 },
        { time: '2024-07-22', value: 21 },
        { time: '2024-07-23', value: 72 }
      ];

      // Set the erningData state
      if (erningData.length > 0) {
        setErninngData(erningData);
      } else {
        setErninngData(erninngDatas);
      }

      const pendingRewards = getPendingRewards.toString() / (10 ** decimalNumber);
      if (pendingRewards > 0) {
        setPendingReward(pendingRewards.toFixed(2));
      }

      let totalUsdtInvested = 0;
      let incomeFromReferral = 0;
      console.log('Get tax data:', getTaxNumbers);
      if (getTaxNumbers.length > 0) {
        for (let i = 0; i < getTaxNumbers.length; i++) {
          const taxData = await dataLibraryContract.methods.getTaxData(getTaxNumbers[i]).call();
          const amountStrings = taxData.amount.toString();
          const taxTypeStrings = taxData.types.toString();
          const amountInUsdt = (amountStrings / (10 ** decimalNumber));
          console.log("Amount Invest update data", amountInUsdt, taxTypeStrings);
          if (taxTypeStrings == 1) {
            totalUsdtInvested += amountInUsdt;
          }
          if (taxTypeStrings == 2) {
            incomeFromReferral += amountInUsdt;
          }
        }

        if (totalUsdtInvested > 0 && totalUsdtInvested < 1e3) {
          setTotalInvest(totalUsdtInvested.toFixed(2));
        } else if (totalUsdtInvested >= 1e3 && totalUsdtInvested < 1e6) {
          const usdtInKilo = totalUsdtInvested / 1e3;
          setTotalInvest(`${usdtInKilo} K`);
        } else if (totalUsdtInvested >= 1e6 && totalUsdtInvested < 1e9) {
          const usdtInKilo = totalUsdtInvested / 1e6;
          setTotalInvest(`${usdtInKilo} K`);
        } else if (totalUsdtInvested >= 1e9) {
          const usdtInKilo = totalUsdtInvested / 1e9;
          setTotalInvest(`${usdtInKilo} K`);
        }

        if (incomeFromReferral > 0 && incomeFromReferral < 1e3) {
          setReferalIncome(incomeFromReferral.toFixed(2));
        } else if (incomeFromReferral >= 1e3 && incomeFromReferral < 1e6) {
          const incomInKilos = (incomeFromReferral / 1e3).toFixed(2);
          setReferalIncome(`${incomInKilos} K`);
        } else if (incomeFromReferral >= 1e6 && incomeFromReferral < 1e9) {
          const incomInKilos = (incomeFromReferral / 1e6).toFixed(2);
          setReferalIncome(`${incomInKilos} M`);
        } else if (incomeFromReferral >= 1e9) {
          const incomInKilos = (incomeFromReferral / 1e9).toFixed(2);
          setReferalIncome(`${incomInKilos} B`);
        }
        console.log('Amount invested', totalUsdtInvested, 'Income from referral:', incomeFromReferral, 'Total Usdt Invested', totalUsdtInvested);
      }

      const depositedTokenString = Number(_depositedToken.toString());
      const depositedToken = (depositedTokenString / (10 ** decimalNumber)).toFixed(2);
      console.log('Total token staked', depositedToken);
      setTotalTokenStakedWithoutK(depositedToken);

      if (depositedToken > 0 && depositedToken < 1e3) {
        setTotalTokenStaked(depositedToken);
      } else if (depositedToken >= 1e3 && depositedToken < 1e6) {
        const strbInKilo = (depositedToken / 1e3).toFixed(2);
        setTotalTokenStaked(`${strbInKilo} K`);
      } else if (depositedToken >= 1e6 && depositedToken < 1e9) {
        const strbInKilo = (depositedToken / 1e6).toFixed(2);
        setTotalTokenStaked(`${strbInKilo} K`);
      } else if (depositedToken >= 1e9) {
        const strbInKilo = (depositedToken / 1e9).toFixed(2);
        setTotalTokenStaked(`${strbInKilo} K`);
      }
      
      if (daysStaked > 300) {
        daysStaked = 300;
      }
      const daysToWithdraw = 300 - daysStaked;
      console.log('Remaining Days', daysToWithdraw);

      if (daysToWithdraw > 0) {
        setRemainingDays(daysToWithdraw);
      } else {
        setRemainingDays(0);
      }
    } catch (error) {
      console.error('Error to update data:', error);
    }
  }, [isConnected, dataLibraryContract, account, setJoiningDate]);

  useEffect(() => {
    if (web3 && masterAddress && tokenAddress && stakingAddress && account && dataLibraryAddress) {
      const contractInstance = new web3.eth.Contract(MasterABI, masterAddress);
      setContract(contractInstance);
      const stakingContractInstance = new web3.eth.Contract(StakingABI, stakingAddress);
      setStakingContract(stakingContractInstance);
      const usdtContractInstance = new web3.eth.Contract(TokenABI, tokenAddress);
      setUsdtContract(usdtContractInstance);
      const timeDateContractInstance = new web3.eth.Contract(DataLibraryABI, dataLibraryAddress);
      setDataLibraryContract(timeDateContractInstance);
      const masterLibraryContractInstance = new web3.eth.Contract(MerchantLibraryABI, merchantLibraryAddress);
      setMerchantLibraryContract(masterLibraryContractInstance);
      const masterContractInstance = new web3.eth.Contract(MerchantABI, merchantAddress);
      setMerchantContract(masterContractInstance);
    }
  }, [account, dataLibraryAddress, web3, masterAddress, tokenAddress, stakingAddress, setContract, setStakingContract, setUsdtContract, setDataLibraryContract]);

  const updateReferral = useCallback(async () => {
    if (!contract || !account || !stakingContract) {
      console.log('Contract or account not available');
      return;
    }
    try {
      console.log("Updating referrals...");
      const tokenDecimal = await usdtContract.methods.decimals().call();
      const decimalNumber = Number(tokenDecimal);
      console.log('Decimal:', decimalNumber);
      const _totalRefferals = await contract.methods.getReferrerLenth(account).call();
      const _todysEarning = await contract.methods.getTodaysEarning(account).call();
      const thisMonth = await contract.methods.getThisMonthEarning(account).call();
      const thisYear = await contract.methods.getThisYearEarning(account).call();
      const earningTotal = await contract.methods.getTotalEarning(account).call();
      const _businessAmount = await stakingContract.methods.rewardForBusness(account).call();
      const businessAmount = _businessAmount.toString() / 10 ** decimalNumber;
      const referalsTotal = _totalRefferals.toString();
      const erningTodays = _todysEarning.toString() / 10 ** decimalNumber;
      const erningThisMonth = thisMonth.toString() / 10 ** decimalNumber;
      const erningThisYear = thisYear.toString() / 10 ** decimalNumber;
      const total_Erning = earningTotal.toString() / 10 ** decimalNumber;
      setTotalRefferals(referalsTotal);
      setTodaysEarning(erningTodays.toFixed(2));
      setThisMonthEarning(erningThisMonth.toFixed(2));
      setThisYearEarning(erningThisYear.toFixed(2));
      setTotalEarning(total_Erning.toFixed(2));
      setBusinessReward(businessAmount);
      console.log("Updating referrals sucsessfuly.", erningTodays, erningThisMonth, erningThisYear, total_Erning);
    } catch (error) {
      console.error("Error updating referrals", error);
    }
  }, [contract, account, totalEarning, totalRefferals]);

  const connectWallet = async () => {
    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const web3Instance = new Web3(window.ethereum);
      const accounts = await web3Instance.eth.getAccounts();
      const userAddress = accounts[0];
      setWeb3(web3Instance);
      setAccount(userAddress);
      setIsConnected(true);
      const usdtApproved = await checkApprovalStatus();
      await updateMilestoneReward();
      setApproved(usdtApproved);
      updateData();
    } catch (error) {
      console.error('Error connecting to wallet:', error);
    }
  };

  const updateMilestoneReward = useCallback(async () => {
    console.log("Updating milestone reward...");
    if (!contract || !account) {
      console.log('Contract or account not available');
      return;
    }
    try {
      const tokenDecimal = await usdtContract.methods.decimals().call();
      const decimalNumber = Number(tokenDecimal);
      console.log('Decimal:', decimalNumber);
      const rewardsAmount = await contract.methods.getMilestonAmount(account).call();
      console.log("Raw milestone amount:", rewardsAmount);
      const rewardsAmountNumber = Number(rewardsAmount.toString());
      const amount = (rewardsAmountNumber / 10 ** decimalNumber).toFixed(2);
      console.log("Formatted milestone amount:", amount);
      setMilestonRewards(amount);
    } catch (error) {
      console.error("Error updating milestone amount", error);
      console.log("Contract address:", contract._address);
      console.log("Account:", account);
    }
  }, [contract, account, setMilestonRewards]);

  const checkApprovalStatus = useCallback(async () => {
    if (!usdtContract || !account || !masterAddress) {
      console.log('usdtContract, account, or masterAddress not available');
      return false;
    }
    try {
      const allowance = await usdtContract.methods.allowance(account, masterAddress).call();
      return allowance > 0;
    } catch (error) {
      console.error('Error checking approval status:', error);
      return false;
    }
  }, [usdtContract, account, masterAddress]);
  
  const disconnectWallet = () => {
    setAccount('');
    setIsConnected(false);
    setApproved(false);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isConnected && usdtContract) {
        console.log('Interval callback running');
        updateDecimals();
        updateData();
        updateReferral();
        updateMilestoneReward();
        checkApprovalStatus();
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [updateData]);

  return (
    <div className="header">
      <div className="header-left">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      {isConnected ? (
        <div className="header-right">
          <button className="logout-btn" onClick={disconnectWallet}><span class="glyphicon log-out glyphicon-log-out"> </span>
            Logout
          </button>
        </div>
      ) : (
        <div className="header-right">
          <button className="connect-btn" onClick={connectWallet}> <span className='glyphicon log-in glyphicon-log-in'></span>
            Connect Wallet
          </button>
        </div>
      )}
    </div>
  );
};

export default Header;