import React from 'react';
import Benner1 from "../assets/ads/example1.png";
import Benner2 from "../assets/ads/example2.png";
import Benner3 from "../assets/ads/example3.png";
import '../styles/BennerAds.css';

const BennerAds = () => {
    const bennerImages = [
        { logo: Benner3, url: 'https://image3.png' },
        { logo: Benner1, url: 'https://image1.png' },
        { logo: Benner2, url: 'https://image2.png' },
        { logo: Benner3, url: 'https://image3.png' },
        { logo: Benner1, url: 'https://image1.png' },
        { logo: Benner2, url: 'https://image2.png' },
        { logo: Benner3, url: 'https://image3.png' },
        { logo: Benner1, url: 'https://image1.png' },
        { logo: Benner2, url: 'https://image2.png' },
        { logo: Benner3, url: 'https://image3.png' },
        { logo: Benner1, url: 'https://image1.png' },
        { logo: Benner2, url: 'https://image2.png' },
    ];

    return (
        <div className="image-ticker">
            <div className="ticker-content">
                {bennerImages.map((images, index) => (
                    <div key={index} className="images-item">
                        <a href={images.url} target="_blank" rel="noopener noreferrer">
                            <img src={images.logo} className="images-logo" alt={`Banner ${index + 1}`} />
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BennerAds;
